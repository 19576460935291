

import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import masonry from '../assets/images/masonry.jpg'
import LaborWarranty from '../components/LaborWarranty'

const Masonry = (props) => (
    <Layout>
        <Helmet>
            <title>Crawlspaces, Brick Siding, & General Masonry</title>
            <meta name="description" content="We provide fantastic Masonry services in the South Eastern part of the USA!" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Crawlspaces, Brick Siding, & General Masonry</h1>
                        <p>$1.75 per Brick (Materials Included)</p>
                    </header>
                    
                    <span className="image main">
                    <img src={masonry} alt="" /></span><br /><br />
                    
                    <header className="major">
                        <h3>What's Included</h3>
                    </header>
                    <p>...</p><br /><br />
                    
                    <header className="major">
                        <h3>Pricing</h3>
                    </header>
                    <ul>
                        <li>How We Price It: </li>
                        <li>Competitor Pricing: </li>
                    </ul><br /><br />
                    
                    <header className="major">
                        <h3>Warranty Information</h3>
                    </header>
                    <ul>
                        <li>Materials Warranty: </li>
                        <li>{LaborWarranty}</li>
                    </ul><br />
                </div>
            </section>
        </div>

    </Layout>
)

export default Masonry